import React from "react"
import {Link} from "gatsby";
import scrollTo from 'gatsby-plugin-smoothscroll';

import './header.scss'

import Logo from '../../svg/logo.svg'
import OpenNav from '../../svg/menu.svg'
import CloseNav from '../../svg/close_menu.svg'
import Instagram from '../../svg/instagram.svg';
import ShoppingCart from '../../svg/shopping-cart.svg';

const Header = () => {
    return (
        <header id="header">
            <div className="container">
                <nav className="flex justify-content-center">
                    <div className="menu-toggle">
                        <button className="icon" onClick={() => document.getElementById('header').classList.toggle('menu-collapsed')}>
                            <OpenNav height={12}/>
                            <CloseNav height={12}/>
                            <span>Menu</span>
                        </button>
                    </div>

                    <ul>
                        <li><button onClick={() => {scrollTo('#home')}}>Úvod</button></li>
                        <li><button onClick={() => scrollTo('#about')}>O produktu</button></li>
                    </ul>

                    <Link to="/">
                        <Logo height="110" />
                    </Link>

                    <ul>
                        <li><button onClick={() => scrollTo('#effects')}>Účinné látky</button></li>
                        <li><button onClick={() => scrollTo('#contact')}>Kontakt</button></li>
                    </ul>

                    <a href="https://www.instagram.com/herbamedicus/" target="_blank" className="instagram">
                        <Instagram height={15}/>
                    </a>

                    <a href="https://www.swissmedicus.cz/unicatum-chondro-250-ml" className="responsive-button button">
                        <span>Koupit</span>
                        <ShoppingCart height={15}/>
                    </a>
                </nav>
            </div>

            <ul className="responsive-menu">
                <li><button onClick={() => {document.getElementById('header').classList.remove('menu-collapsed'); scrollTo('#home')}}>Úvod</button></li>
                <li><button onClick={() => {document.getElementById('header').classList.remove('menu-collapsed'); scrollTo('#about')}}>O produktu</button></li>
                <li><button onClick={() => {document.getElementById('header').classList.remove('menu-collapsed'); scrollTo('#effects')}}>Účinné látky</button></li>
                <li><button onClick={() => {document.getElementById('header').classList.remove('menu-collapsed'); scrollTo('#contact')}}>Kontakt</button></li>
            </ul>

            <a href="https://www.swissmedicus.cz/unicatum-chondro-250-ml" target="_blank" className="button">
                Koupit
                <ShoppingCart height={15}/>
            </a>
        </header>
    )
}

export default Header
