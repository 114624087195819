import React from "react"
import {useStaticQuery, graphql} from 'gatsby'

import './cover.scss'
import Img from "gatsby-image"

const Cover = () => {
    const data = useStaticQuery(graphql`
        query {
            file: file(relativePath: { eq: "cover.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            file2: file(relativePath: { eq: "vizualizace.png" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
        }
      `
    )

    return (
        <section className="cover" id="home">
            <Img fluid={data.file.childImageSharp.fluid}/>
            <div className="container flex justify-content-right align-items-center">
                <div className="text">
                    <h1>Rašelinový balzám</h1>
                    <h2>UNIKÁTNÍ MAZÁNÍ PRO VAŠE KLOUBY, SVALY A PÁTEŘ</h2>
                    <p>Unikátní balzám s vysokým obsahem bylin<br/> a koncentrovaným extraktem z rašeliny</p>
                </div>
            </div>
            <Img fluid={data.file2.childImageSharp.fluid}/>
        </section>
    )
}

export default Cover
