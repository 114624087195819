import React from "react"
import {useStaticQuery, graphql} from 'gatsby'

import './parallax.scss'
import BackgroundImage from 'gatsby-background-image'
import ParIcon from '../../svg/par-icon.svg'

const Parallax = (props) => {
    const data = useStaticQuery(graphql`
        query {
            file: file(relativePath: { eq: "parallax-1.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            file2: file(relativePath: { eq: "parallax-2.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            file3: file(relativePath: { eq: "parallax-3.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
        }
      `
    )

    let imageData;

    if(props.image === 1) {
        imageData = data.file.childImageSharp.fluid
    } else if(props.image === 2) {
        imageData = data.file2.childImageSharp.fluid
    } else {
        imageData = data.file3.childImageSharp.fluid;
    }

    const label = props.label
    const title = props.title

    if(props.image === 2) {
        return (
            <BackgroundImage Tag="section" className="parallax text-align-center" fluid={imageData}>
                <div className="container">
                    <div className="text">
                        <p className="label">{label}</p>
                        <h2>vhodný k aplikaci několika způsoby:<br /> mazání <ParIcon width={40} /> masáže <ParIcon width={40} /> obklady <ParIcon width={40} /> zábaly <ParIcon width={40} /> koupele</h2>
                    </div>
                </div>
            </BackgroundImage>
        )
    }

    return (
        <BackgroundImage Tag="section" className="parallax text-align-center" fluid={imageData}>
            <div className="container">
                <div className="text">
                    <p className="label">{label}</p>
                    <h2 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
            </div>
        </BackgroundImage>
    )
}

export default Parallax
