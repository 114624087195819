import React from "react"

import SEO from "../components/seo"

import './index.scss'

import Header from "../components/header/header"
import Cover from "../components/cover/cover"
import Parallax from "../components/parallax/parallax";
import Footer from "../components/footer/footer";
import LidlBig from "../components/lidl-big/lidl-big";
import About from "../components/about/about";
import Effects from "../components/effects/effects";
import Modal from "../components/modal/modal";
import Product from "../components/product/product";

const IndexPage = () => (
    <>
        <SEO title="Rašelinový balzám"/>
        <Header/>
        <Cover/>
        <About/>
        <Parallax label="Objevte" title="miliony let vznikající sílu rašeliny<br /> a výtažků bylin" image={1}/>
        <Product/>
        <Parallax label="Rašelinový balzám" title="vhodný k aplikaci několika způsoby:<br /> mazání <span></span> masáže <span></span> obklady <span></span> zábaly <span></span> koupele" image={2}/>
        <Effects/>
        <Parallax label="Rašelinový balzám" title="obsahuje již po několik generací<br /> prověřené léčivé byliny " image={3}/>
        <Footer/>
    </>
)

export default IndexPage
