import React from "react"
import {useStaticQuery, graphql} from 'gatsby'

import './effects.scss'
import Img from "gatsby-image"

const Effects = () => {
    const data = useStaticQuery(graphql`
        query {
            file: file(relativePath: { eq: "effects.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 960) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
        }
      `
    )

    return (
        <section className="effects" id="effects">
            <div className="container flex align-items-center justify-content-space-between">
                <Img fluid={data.file.childImageSharp.fluid} />

                <div className="text">
                    <p>
                        Rašelinový balzám UNICATUM CHONDRO má díky svému unikátnímu složení široké spektrum účinků. Koncentrovaný rašelinový extrakt a směs bylin obsahují látky, o kterých
                        je známo, že pomáhají při:
                    </p>

                    <ul>
                        <li>Bolestech kloubů, svalů a páteře</li>
                        <li>Artróze a revmatických potížích</li>
                        <li>Otocích kloubů a dolních končetin</li>
                        <li>Rekonvalescenci po úrazech</li>
                        <li>Svalové únavě a pohmožděninách</li>
                        <li>Zvyšování elasticity cévních stěn</li>
                        <li>Celkové regeneraci pohybového aparátu</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Effects
