import React from "react"
import {useStaticQuery, graphql} from 'gatsby'

import './about.scss'
import Img from "gatsby-image"

import Icon1 from '../../svg/icon_1.svg'
import Icon2 from '../../svg/icon_2.svg'
import Icon3 from '../../svg/icon_3.svg'

const About = () => {
    const data = useStaticQuery(graphql`
        query {
            file: file(relativePath: { eq: "about-1.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 960) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            file2: file(relativePath: { eq: "about-2.png" }) {
              childImageSharp {
                fixed(quality: 100, width: 250) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            },
            file3: file(relativePath: { eq: "about-3.jpg" }) {
              childImageSharp {
                fixed(quality: 100, width: 300) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            },
            file4: file(relativePath: { eq: "about-4.png" }) {
              childImageSharp {
                fixed(quality: 100, width: 300) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
        }
      `
    )

    return (
        <section className="about text-align-center" id="about">
            <Img fluid={data.file.childImageSharp.fluid} />
            <Img fluid={data.file2.childImageSharp.fixed} />
            <Img fluid={data.file3.childImageSharp.fixed} />
            <Img fluid={data.file4.childImageSharp.fixed} />

            <div className="container">
                <div className="text">
                    <p className="label">UNICATUM CHONDRO</p>
                    <h2>Švýcarský<br /> rašelinový balzám<br /> s bylinnými extrakty</h2>

                    <p>
                        Tato unikátní receptura obsahuje vyvážený poměr vysoce koncentrovaného extraktu z rašeliny a výtažků
                        z bylin, o kterých je známo, že ulevují při bolestech kloubů, svalů a páteře.
                    </p>

                    <p>
                        <strong>Unikátnost tohoto balzámu spočívá ve vyváženém spojení staletími prověřených ingrediencí, jako jsou rašelina a léčivé byliny.</strong>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default About
