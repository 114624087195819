import React from "react"
import {useStaticQuery, graphql} from 'gatsby'

import './product.scss'
import Img from "gatsby-image"

const Product = () => {
    const data = useStaticQuery(graphql`
        query {
            file: file(relativePath: { eq: "product.png" }) {
              childImageSharp {
                fixed(quality: 100, width: 250) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            },
        }
      `
    )

    return (
        <section className="product text-align-center" id="product">
            <div className="container">
                <div className="text">
                    <p>
                        Rašelina je léčebný prostředek prověřený staletími. Ve formě rašelinových zábalů ji při artrózách a revmatických onemocněních používali již staří Egypťané a později také Řekové.
                        Ulevovali si tak nejen od bolestí kloubů, svalů a páteře, ale i od ekzémů, akné a kožních zánětů.
                    </p>

                    <h2>
                        <Img fluid={data.file.childImageSharp.fixed} />
                        <span>Léčivé</span>
                        <span>byliny</span>
                        <span>JSOU PROVĚŘENÝM PROSTŘEDKEM</span>
                        <span>pro řešení zdravotních potíží</span>
                    </h2>

                    <p>
                        Léčivé byliny jako je například Kostival lékařský, Jalovec obecný, Kaštan koňský, Hořec, Arnika, Rozrazil lékařský a další jsou již generacemi prověřeným prostředkem při řešení potíží s pohybovým aparátem.
                        Některé jejich složky příznivě působí při bolestech kloubů, svalů a páteře. Napomáhají při rekonvalescenci po úrazech, mají protizánětlivý účinek a omezují tvorbu otoků.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Product
